.name {
  width: unset;
  font-weight: 600;
  font-size: 14px;
  color: #39332e;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px 0;
}
