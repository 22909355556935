.obCustomize {
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
  }
  .obCustomize-input {
    margin-top: 20px;
  }
}
