.reviewCardMain {
  .ant-btn {
    border: none;
  }
  /* margin: 20px 10px; */
  border-radius: 10px;
  background-color: white;
  //box-shadow: 0px 2px 10px 2px rgba(154, 154, 154, 0.27);
  min-height: 100%;
  height: auto;
  width: 100%;
  max-width: 1216px;
  margin: auto;
  display: flex;
  padding: 20px;
  flex-direction: column;
  .average-container {
    display: flex;
    justify-content: space-between;
    transition: all ease-in-out 0.2s;
    .average-content {
      min-width: 60%;
      display: flex;
      flex-direction: column;
    }
  }
  .average-container-minimial {
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid rgb(230, 230, 230);
  }
  @media (max-width: 585px) {
    .average-container {
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;
      .average-content {
        width: 100%;
      }
    }
  }
}

.reviewCardMain h2 {
  color: black;
  font-weight: 600;
}

.rating-row {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.rating-row h2 {
  margin-left: 20px;
}

.cardsContainer {
  display: flex;
  padding-top: 20px;
}

.writeBtn {
  background-color: #5c6ac4;
  width: 150px;
  height: 45px;
  /* border-radius: 100px; */
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.avgRatingBox {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  background-color: #5c6ac4;
}
