.inputWrapper {
  padding: 2%;
  .inputWrapper-titleCont {
    padding-bottom: 4px;
    .inputWrapper-title {
      font-size: 15px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      padding-right: 5px;
    }
    .inputWrapper-helpIcon {
      color: gray;
      font-size: 15px;
      cursor: pointer;
      margin: 0;
      margin-top: 5px;
    }
  }
  .inputWrapper-children {
    display: flex;
  }
  .inputWrapper-message {
    padding: 2px 5px;
    b {
      font-weight: 500;
    }
  }
  .inputWrapper-doubleMain {
    width: 75%;
    margin-right: 5px;
    @media only screen and (max-width: 700px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  .inputWrapper-doubleSub {
    width: 25%;
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 700px) {
    .inputWrapper-children {
      flex-wrap: wrap;
    }
  }
}
