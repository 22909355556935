.load-more {
  background-color: #5c6ac4;
  width: 140px;
  height: 50px;
  border-radius: 100px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  margin-top: 40px;
}

.loadMoreBtnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
