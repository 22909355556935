.revyuHighlightTemplate {
  background-color: #00000011;
  color: #000000;
  font-weight: 500;
  border-radius: 4px;
  padding: 2px 10px;
  padding-bottom: 4px;
  margin: 0;
}

.customRevyuCollapse .customRevyuCollapsePanel {
  margin-bottom: 24px;
  overflow: hidden;
  background: white;
  border: 0px;
  border-radius: 10px !important;
  // box-shadow: rgba(154, 154, 154, 0.27) 0px 2px 10px 2px;
}
.customRevyuCollapse {
  background-color: transparent !important;
  margin-bottom: 20px;
  min-height: 60px;
}

.ant-collapse-header {
  min-height: 60px;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.ant-collapse-item-active .ant-collapse-header {
  color: #5f65f2 !important;
}

.ant-image-preview-img {
  max-height: 70% !important;
  max-width: 80% !important;
}
