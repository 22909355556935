.reviewCard-dynamic {
  flex-direction: column;
  padding: 20px 20px;
  margin: 15px 0;
  width: 350px;
  overflow: hidden;
  // .stars-group {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .dynamicBody {
    .dynamicHeader {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      .dynamicInfo {
        margin: 0 10px;
        margin-right: 20px;
      }
    }
  }
  .reviewCardFigure {
    flex: 2;
    height: auto;
    width: 310px;
    padding: 1px 0;
    img {
      width: 120px;
      height: 120px;
      background-size: cover;
      object-fit: cover;
    }
  }
}
