.onboardingBody {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 75px);
  .onboardingLeft {
    flex: 3;
    display: flex;
    flex-direction: column;
    .obContent {
      padding: 10px 50px;
      margin: auto 0;

      h1.obTitle {
        font-weight: 700;
        font-size: 36px;
        line-height: 52px;
      }
      .obChildren {
        margin: 24px 0;
      }
    }
  }
  .onboardingRight {
    flex: 3;
    background-color: #fbf8f5;
    align-items: center;
    justify-content: center;
    display: none;
    img,
    svg {
      max-width: 100%;
    }
  }
  @media only screen and (min-width: 600px) {
    .onboardingLeft {
      .obContent {
        padding: 25px 75px;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .onboardingRight {
      display: flex;
    }
  }
}
