    .content__footer {
        margin-top: 15px;
        display: flex;
        // align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        .content__footer_text {
            // margin-left: 10px;
            padding: 0 10px;
        }
        h3 {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            .content__footer_text {
                // margin-left: 10px;
                padding: 0 10px;
                max-width: 100%;
            }
            h3 {
                display: flex;
                align-items: center;
            }
            p {
                margin-top: 5px;
                max-width: 100%;
                margin-left: 5px;
            }
        }
    }