.coupons {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  .couponBox {
    background-color: white;
    padding: 30px;
    padding-left: 50px;
    border-radius: 12px;
    // margin: 10px;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    .couponHeader {
      h1 {
        font-size: 18px;
      }
      p {
        color: rgba(71, 71, 71, 0.795);
        margin-top: 20px;
      }
    }
    .couponContent {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      height: 100%;
      .couponForm {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        // background-color: rebeccapurple;
        height: 100%;
        .couponInput {
          display: flex;
          flex-direction: column;
          margin: 20px 0;
          width: 100%;
          .couponCodeInput,
          .couponTypeInput {
            font-size: 15px;
            width: 100%;
            margin: 5px 0;
          }
        }
        .applyBtn {
          margin: auto;
        }
        // .applyBtn-appsumo {
        //   background-color: #353d44;
        // }
        // .applyBtn-pitchground {
        //   background-color: #fe0001;
        // }
      }
      .couponLogo {
        width: 180px;
        margin: 20px auto;
        margin-bottom: 25px;
      }
    }
  }
  @media (min-width: 600px) {
    .couponBox {
      .couponContent {
        .couponForm {
          .couponInput {
            flex-direction: row;
            width: 100%;
            .couponCodeInput,
            .couponTypeInput {
              min-width: 200px;
              width: unset;
              margin-right: 10px;
            }
            .couponCodeInput {
              width: 350px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 767px) {
    // flex-direction: row;
    .couponBox {
      .couponContent {
        flex-direction: row;
        justify-content: space-between;
        .couponForm {
          width: 70%;
          .applyBtn {
            margin: unset;
          }
        }
        .couponLogo {
          margin: auto;
        }
      }
    }
  }
  @media (min-width: 1600px) {
    // width: 75%;
    justify-content: space-between;
  }
}

.planListItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  .planListItem {
    display: flex;
    align-items: center;
    padding: 8px 0;
    svg {
      color: #20c997;
    }
    p {
      margin: 0;
      padding-left: 10px;
    }
  }
}
