.reviewCardFigure {
  height: 125px;
  margin: 0;
}

.reviewCardFigure-multiple {
  display: flex;
  overflow: auto;
  margin: 10px 0;
  .ant-image {
    margin-right: 10px;
    margin-bottom: 10px;
    img,
    .ant-image-mask {
      border-radius: 4px;
    }
  }
  &::-webkit-scrollbar {
    height: 5px;
  }
}

.reviewCardFigure-single {
  position: relative;
  figcaption {
    font-weight: 700;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 9;
    color: #55514e;
    padding: 2px 9px;
    font-size: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    line-height: inherit;
    background: hsla(0, 0%, 100%, 0.6);
    border-radius: 4px;
    cursor: pointer;
  }
}
