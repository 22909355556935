.onboardingLayout {
  .onboardingHeader {
    display: flex;
    align-items: center;
    height: 75px;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03);
    padding: 0 70px;
    img {
      max-height: 42px;
    }
    img:nth-child(2) {
      max-height: 26px;
      margin-left: 15px;
    }
  }
}
