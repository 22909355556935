// Modal
.customCardModal {
  .ant-modal-body {
    padding: 0;
    width: 100%;
    height: fit-content;
    padding-bottom: 15px;
    .ant-divider-horizontal {
      margin-bottom: 12px;
    }
    .modalHolder {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 10px;
      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        p,
        h3,
        h2 {
          margin: 0;
          padding: 0;
        }
        .content__header {
          flex: 0.2;
          display: flex;
          > div {
            flex: 1;
          }
          p {
            margin-top: 5px;
          }
        }
        .content__review {
          flex: 0.6;
          margin-top: 15px;
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .preview {
        flex: 1;
        width: 100%;
        .reviewCardFigure {
          flex: 2;
          height: auto;
          width: 450px;
          padding: 1px 10px;

          img {
            width: 120px;
            height: 120px;
          }
        }
        p {
          font-size: 14px;
          padding: 5px 10px;
          margin: 0;
        }
      }
    }
  }
}
