.list-main {
  max-width: 1240px;
  margin: 0 auto;
  padding: 20px 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 40px;
  width: 100%;
}

.load-more {
  background-color: #5c6ac4;
  width: 140px;
  height: 50px;
  border-radius: 100px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  margin-top: 40px;
}

.reviewList-list {
  // width: 720px;
  width: 100%;
  display: flex;
  // height: fit-content;
  // flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.reviewList-dynamic {
  display: flex;
  // align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.reviewList-zedge {
  display: flex;
  // align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
