.ratingContMain {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  flex: 0.8;
  .rating-item {
    display: flex;
    align-items: center;
    .customProgressBar {
      width: 40%;
      margin-left: 20px;
    }
  }
}

.ratingContMain .ratingContMain p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  margin-left: 20px;
}
