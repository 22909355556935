.reviewCard-grid {
  flex-direction: column;
  position: relative;
  clear: both;
  margin: 10px 4px;
  border-radius: 5px;
  .imgContainer {
    .imgContainer-ant-image {
      width: 100px;
      height: 100px;
    }
  }
  .card-body {
    .name {
      max-width: 100px;
    }
  }
  .card-img {
    height: fit-content !important;
    // height: auto;
    .reviewCardFigure {
      display: block;
      height: 100%;

      img,
      .ant-image-mask {
        border-radius: 4px 4px 0 0 !important;
      }
      img {
        max-width: 100%;
        // height: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}
