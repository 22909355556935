.author-container {
  display: inline-flex;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #39332e;
  .author-content {
    display: flex;
    flex: 0.8;
  }
}
