.clearfix {
  clear: both;
  text-align: center;
  .stars-container {
    height: 26px;
    // display: inline-block !important;
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto;
    background: #fff;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 1px 12px;
    position: relative;
    top: -13px;
    .stars-group {
      cursor: default;
      white-space: nowrap;
      margin: 0 !important;
    }
  }
}
