h2.obInputTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
}
.obSelectBlock {
  padding: 10px 20px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  border: 1px solid #adadad;
  color: #4d4d57;
  margin-right: 20px;
  transition: all ease-in-out 0.2s;
  .feather {
    fill: transparent;
    transition: all ease-in-out 0.2s;
  }
}
.obSelectBlock-selected {
  border: 1px solid #ff8906;
  color: #ff8906;
  .feather {
    fill: #ff8906;
  }
}

.doNowSelect {
  border: 2px solid #bdbdbd;
  border-radius: 10px;
  padding: 15px 30px;
  margin: 18px 0;
  cursor: pointer;
  max-width: 600px;
  transition: all ease-in-out 0.2s;
  h2,
  p {
    margin: 0;
    transition: all ease-in-out 0.2s;
  }
  h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #4d4d57;
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #adadad;
  }
}
.doNowSelect-selected {
  border: 2px solid #ff8906;
  h2 {
    color: #ff8906;
  }
  p {
    color: #8b8b8b;
  }
}
