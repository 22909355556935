.revyu-blur-loader-loading {
  transition: all 0.5s ease;
  background-color: #00000090;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 10000;
}
.revyu-blur-loader-not-loading {
  transition: all 0.5s ease;
  background-color: #00000000;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  z-index: -10000;
}

.revyu-blur-loader {
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .loader {
    width: 48px;
    height: 48px;
    position: relative;
    perspective: 500px;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 48px;
    background: #fa8b0c;
    border-radius: 0 24px 24px 0;
    transform-origin: 0 0;
    animation: flip 2s linear infinite alternate;
  }
  .loader:after {
    left: 0;
    border-radius: 24px 0 0 24px;
    transform-origin: 100% 0;
    animation-delay: 1s;
  }

  @keyframes flip {
    0%,
    10% {
      transform: rotateY(0deg);
    }
    90%,
    100% {
      transform: rotateY(-180deg);
    }
  }
  p {
    margin-top: 20px;
    font-size: 20px;
    color: #fff0df;
    font-weight: 500;
  }
  .ant-spin-lg .ant-spin-dot {
    font-size: 60px;
  }

  .ant-spin-lg .ant-spin-dot i {
    width: 25px;
    height: 25px;
  }
  .ant-spin {
    .ant-spin-dot-item {
      background-color: #fa8b0c;
    }
  }
}
