.overlay-override {
  .ant-popover-content {
    .ant-popover-inner,
    .ant-popover-arrow {
      background-color: rgb(253, 227, 207) !important;
      border-color: rgb(253, 227, 207) !important;
    }
    .ant-popover-inner .ant-popover-inner-content,
    .ant-popover-arrow .ant-popover-arrow-content {
      color: rgb(245, 106, 0) !important;
    }
  }
}
