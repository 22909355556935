.reviewCard-list {
  height: 180px;
  width: 640px;
  margin: 15px 10px;
  overflow: hidden;
  .listBody {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin: 0 auto;
    .listFooter {
      align-items: center;
    }
    .listFooter,
    .listHeader {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      // justify-content: center;
      .listFooterR {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .name {
          width: 100% !important;
          margin-top: 10px;
        }
      }
    }
  }

  .reviewCardFigure {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: auto;
    img {
      width: 100%;
      height: 100%;
      background-size: cover;
      object-fit: cover;
      // display: none;
    }
  }
}
