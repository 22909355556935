.flag {
  width: fit-content;
  height: fit-content;
  margin: 2px 0;
  img {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    object-fit: cover;
    font-size: 2em;
    line-height: 2em;
    transition: all ease-in-out 0.2s;
  }
}
.flag-rectangle {
  img {
    border-radius: 4px;
    width: 32px;
    height: 24px;
    min-width: 32px;
    min-height: 24px;
  }
}
.flag-round {
  img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  }
}
