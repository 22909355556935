.purchaseModal {
  .ant-modal-body {
    padding-bottom: 10px;
  }
  .ant-modal-close-x {
    color: white;
  }
  .ant-modal-header {
    background-color: #182033;
    color: white;
    border: none;

    .ant-modal-title {
      // h4 {
      color: white;
      // }
    }
  }
}
.purchaseCredits {
  .purchaseCredits-amntWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .purchaseCredits-amnt {
      width: fit-content;
      p,
      h3 {
        margin: 0;
        text-align: right;
      }
      p {
        color: darkgray;
      }
      h3 {
        color: #5f63f2;
        span {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }
  .purchaseCredits-submitWrap {
    display: flex;
    justify-content: flex-end;
    height: fit-content;
    .purchaseCredits-submit {
      width: fit-content;
    }
  }
}
.stripeCardInput {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  // align-items: center;
}
