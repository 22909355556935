.devicePreviewBtn {
  box-shadow: 0px 0px 10px 8px rgb(235, 235, 235);

  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  .deviceIcon {
    background-color: white;
    color: dodgerblue;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
  }
  .deviceIcon-active {
    background-color: dodgerblue;
    color: white;
  }
}
