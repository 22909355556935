.rPlanCard {
  .golden {
    color: goldenrod;
    strike {
      font-size: 22px;
      padding: 0 5px;
    }
  }
  .rPlanCard-badge {
    padding: 0;
    margin: 20px 0;
    font-weight: 500;
    font-size: 20;
  }
  .rPlanCard-showMore {
    text-align: right;
    cursor: pointer;
    padding: 10px 0;
  }
  .animate {
    animation: infinite reverse buttonAnimation
      cubic-bezier(0.445, 0.05, 0.55, 0.95) 1s;

    @keyframes buttonAnimation {
      0% {
        transform: translateX(0px);
      }
      10% {
        transform: translateX(10px);
      }
      20% {
        transform: translateX(-10px);
      }
      30% {
        transform: translateX(10px);
      }
      40% {
        transform: translateX(-10px);
      }
      50% {
        transform: translateX(0px);
      }
      60% {
        transform: translateX(0px);
      }
    }
  }
}
