.card-body {
  border: none;
  width: 100%;
  text-align: center;
  padding-bottom: 8px;
  .desc {
    padding: 0 16px 8px !important;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .replyContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
    h3 {
      font-size: 16px;
      font-weight: 600;
    }

    em {
      color: rgb(155, 155, 155);
    }
  }
}

.review-text {
  height: auto !important;
  line-height: 18px;
  word-break: break-word;
  margin: 10px 0;
}
