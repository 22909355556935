.reviewContMain {
  width: 100%;
  .ant-select {
    width: 100%;

    .ant-select-selector {
      min-height: 48px !important;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .rating-form {
    display: flex;
    flex-direction: column;
  }

  .rating-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .ant-rate-star {
      font-size: 30px;
    }
  }

  .customInput {
    margin: 10px 10px 15px 0;
  }

  .upload-container {
    width: 100%;
  }

  .ant-upload-list {
    display: flex;
    justify-content: center;
  }

  .submitBtnContainer {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    .submitBtn {
      background-color: #5c6ac4;
      width: 150px;
      height: 45px;
      /* border-radius: 100px; */
      color: white;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.atbd-form-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
