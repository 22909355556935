.showOnlyInMobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .hideInMobile {
    display: none;
  }
  .showOnlyInMobile {
    display: block;
  }
}

.creditsPop {
  .ant-popover-inner {
    padding: 10px;
  }
}

.creditsCol {
  .creditsRow {
    display: flex;
    align-items: center;
    margin: 10px 0;
    svg {
      height: 18px;
      color: rgb(190, 190, 190);
    }
    p {
      margin: 0;
      margin-left: 8px;
      display: flex;
      align-items: center;
      span {
        color: #ff69a5;
        // color: #5f63f2;
        font-weight: 600;
        margin-right: 4px;
      }
    }
  }
}
// }
