.reviewCard-zedge {
  margin: 15px 10px;
  max-width: 350px;
  overflow: hidden;
  .zedgeBody {
    flex: 3;
    padding: 25px 20px;
    .zedgeHeader {
      margin-bottom: 10px;
      display: flex;
      .zedgeInfo {
        margin: 0 10px;
        margin-right: 20px;
      }
    }
  }
  .reviewCardFigure {
    flex: 2;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // padding: 1px 0;
    .ant-image {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        background-size: cover;
        object-fit: cover;
      }
    }
  }
}
