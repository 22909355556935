.avatar {
  position: relative;
  width: fit-content;
  img {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 1px solid #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    margin-right: 12px;
  }
  .avatarLetter {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 2px solid #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
  }
  span {
    border: 1px solid #fff;
    font-size: 11px;
    position: absolute;
    right: 9px;
    bottom: -2px;
    background: #fff;
    border-radius: 50%;
    color: #00c35c;
  }
}
